<template>
  <div class="check-car" ref="checkCar">
    <div class="form-area table-search" ref="formDiv">
      <el-form
        class="demo-form-inline"
        ref="form"
        :model="form"
        label-position="left"
        label-width="82px"
        :inline="true"
        :rules="rules"
      >
        <el-form-item label="日期：" prop="day">
          <el-date-picker
            v-model="form.day"
            align="right"
            type="date"
            placeholder="选择日期"
            :picker-options="pickerOptions"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="时间段：" prop="time">
          <el-time-picker
            is-range
            v-model="form.time"
            range-separator="至"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
            placeholder="选择时间范围"
            format="HH:mm"
          ></el-time-picker>
        </el-form-item>

        <el-form-item label="车辆线路：">
          <el-select
            class="select-width"
            v-model="form.companyId"
            multiple
            filterable
            remote
            collapse-tags
            clearable
            placeholder="请输入线路名称"
            :remote-method="remoteMethod"
            :loading="loading"
            :multiple-limit="20"
            :reserve-keyword="false"
          >
            <el-option
              v-for="item in options"
              :key="item.companyId"
              :label="item.companyName"
              :value="item.companyId"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="所在城市：" prop="city">
          <el-cascader :options="cityList" v-model="city"></el-cascader>
        </el-form-item>
        <el-form-item label="站点名称：" prop="stationId">
          <el-select
            ref="select_element"
            v-model="form.stationId"
            clearable
            filterable
            :filter-method="filterStation"
            placeholder="请输入"
            @change="selectVal"
          >
            <el-option
              v-for="item in stationList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item class="alarm-btn">
          <el-button size="small" type="primary" :loading="searchLoading" @click="search">查询</el-button>
          <el-button size="small" type="primary" :disabled="tableData.length === 0" :loading="downLoadStatus" @click="report">导出</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="main">
      <div class="main-table">
        <el-table :data="tableData" stripe :height="tableHeight">
          <el-table-column prop="cph" label="车牌号" width="100"></el-table-column>
          <el-table-column prop="lineName" label="线路名称" show-overflow-tooltip></el-table-column>
          <el-table-column prop="moveType" label="运行方向">
            <template slot-scope="scope">{{scope.row.moveType === 1?'上行':'下行'}}</template>
          </el-table-column>
          <el-table-column prop="arriveTime" label="到站时间" width="160"></el-table-column>
          <el-table-column prop="leaveTime" label="离站时间" width="160"></el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button
                @click="showMapLine(scope.row)"
                type="text"
                size="small"
                sort="primary"
              >查看轨迹</el-button>
            </template>
          </el-table-column>
        </el-table>
        <div class="pagination" ref="pagination">
          <el-pagination
            background
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
            :page-size="form.pageSize"
            :current-page="form.currentPage"
            @current-change="onCurrentChange"
            @size-change="onSizeChange"
          ></el-pagination>
        </div>
      </div>
      <div class="main-map">
        <search-map ref="searchMap" :locatePointShow="locatePointShow" />
      </div>
    </div>
  </div>
</template>
<script>
import { queryLineDetailFuzzy, querySiteFuzzy, gethistoryTrackNew,addExcelExport } from '@/api/lib/refreshAlarm.js'
import { queryAreaList } from '@/api/lib/api.js'
import { selectHistoryStationInfo } from '@/api/lib/bus-api.js'
import {
  formatDate,
  formatDay
} from '@/common/utils/index'
import searchMap from '@/views/monitor/alarmSetting/components/map.vue'
export default {
  components: {
    searchMap
  },
  data () {
    let validateDate = (rule, value, callback) => {
      if (value && value !== '') {
        let day = formatDay(new Date())
        let startTime = `${day} ${formatDate(value[0]).split(' ')[1]}`
        let endTime = `${day} ${formatDate(value[1]).split(' ')[1]}`
        if (new Date(startTime) >= new Date(endTime)) {
          callback(new Error(`开始时间必须小于结束时间`))
        } else {
          callback()
        }
      } else {
        callback(new Error(`请选择`))
      }
    }
    return {
      pickerOptions: {
        disabledDate (time) {
          return time.getTime() > Date.now()
        },
        shortcuts: [
          {
            text: '今天',
            onClick (picker) {
              let now = new Date()
              let today =
                now - (now % (24 * 60 * 60 * 1000)) - 8 * 60 * 60 * 1000
              picker.$emit('pick', today)
            }
          },
          {
            text: '昨天',
            onClick (picker) {
              let now = new Date()
              let oneDay = 1000 * 60 * 60 * 24
              let today =
                now - (now % (24 * 60 * 60 * 1000)) - 8 * 60 * 60 * 1000
              let date = today - oneDay
              picker.$emit('pick', date)
            }
          },
          {
            text: '一周前',
            onClick (picker) {
              let now = new Date()
              let oneDay = 1000 * 60 * 60 * 24
              let today =
                now - (now % (24 * 60 * 60 * 1000)) - 8 * 60 * 60 * 1000
              let date = today - oneDay * 7
              picker.$emit('pick', date)
            }
          }
        ]
      },
      city: [510000, 510800],//四川广元
      form: {
        day: new Date(),
        time: null,
        companyId: null,
        stationId: null,
        currentPage: 1,
        pageSize: 10
      },
      reportForm: null,
      currentForm: null,
      total: 0,
      stationList: [],
      loading: false,
      options: [],
      cityList: [],
      rules: {
        day: [
          {
            type: 'date',
            required: true,
            message: '请选择日期',
            trigger: 'change'
          }
        ],
        time: [{ validator: validateDate, trigger: 'change' },
        { required: true, message: '请选择时间段', trigger: 'blur' }
        ],
        stationId: [{ required: true, message: '请选择站点', trigger: 'blur' }]
      },
      searchLoading: false,
      downLoadStatus:false,
      tableData: [],
      tableHeight: 0,
      locatePointShow: null,//报警点地图定位点
      system: sessionStorage.getItem("system"),
      curStation: null,
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.computeHeight();
      this.getAreaList();
      window.addEventListener('resize', this.computeHeight, false)
    })
  },
  destroyed () {
    window.removeEventListener('resize', this.computeHeight)
  },
  methods: {
    showMapLine (data) {
      let deviceType = this.system === "2" ? "0" : this.system;
      const par = {
        system: 0,
        beginTime: `${formatDay(this.currentForm.day)} ${
          formatDate(this.currentForm.time[0]).split(' ')[1]
          }`,
        endTime: `${formatDay(this.currentForm.day)} ${
          formatDate(this.currentForm.time[1]).split(' ')[1]
          }`,
        vehId: data.vehicleId,
        deviceType
      }

      gethistoryTrackNew(par).then(res => {
        if (res.code === 1000) {
          this.$nextTick(() => {
            let track = {
              positionDataDTOList: res.data.historyTrackList || []
            }
            this.locatePointShow = {
              longitude: this.curStation.longitude,
              latitude: this.curStation.latitude,
              alarmSignTime: data.arriveTime
            }
            this.$refs.searchMap.onTrailPlay(track)
          })
        }
      })
    },
    selectVal (val) {
      this.curStation = this.stationList.find(item => item.id === val) || null;
    },
    // 计算表格高度
    computeHeight () {
      const wholeHeight = this.$refs.checkCar.clientHeight
      const formHeight = this.$refs.formDiv.clientHeight
      const paginationHeight = this.$refs.pagination.clientHeight
      this.tableHeight = wholeHeight - 16 - formHeight - paginationHeight - 10 - 16;
    },
    search () {
      this.form.currentPage = 1
      this.form.pageSize = 10
      //暂存查询条件
      this.currentForm = { ...this.form }
      this.getListByField()
    },
    report () {
      this.downLoadStatus = true;
        let param = { ...this.reportForm }
        delete param.currentPage
        delete param.pageSize
        const data = {
          baseUrl:'bus',
          fileName:'站点查车',
          queryParam:JSON.stringify(param),
          queryPath:'/station/exportHistoryStationInfo'
        }
        addExcelExport(data).then((res) => {
          this.$message.success(res.msg)
          this.$store.dispatch('setRefreshStatus', true)
        })
        .finally(() => {
          this.downLoadStatus = false
        })
    },
    getListByField () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.searchLoading = true
          let data = {
            startTime: `${formatDay(this.currentForm.day)} ${
              formatDate(this.currentForm.time[0]).split(' ')[1]
              }`,
            endTime: `${formatDay(this.currentForm.day)} ${
              formatDate(this.currentForm.time[1]).split(' ')[1]
              }`,
            currentPage: this.currentForm.currentPage,
            pageSize: this.currentForm.pageSize,
            stationId: this.currentForm.stationId,
            companyId: this.currentForm.companyId[0] || null
          }
          selectHistoryStationInfo(data).then(res => {
            if (res.code === 1000) {
              this.searchLoading = false;
              this.tableData = res.data.list || [];
              this.total = res.data.total;
              this.reportForm = { ...data };
            }else{
              this.searchLoading = false;
              this.$message.warning(res.msg)
            }
          }).catch(err=>{
            this.searchLoading = false;
            this.$message.warning(err.msg)
          })
        }
      })
    },
    // 当前页码改变
    onCurrentChange (page) {
      this.form.currentPage = page
      this.getListByField()
    },
    // 当前条数改变
    onSizeChange (size) {
      this.form.pageSize = size
      this.form.currentPage = 1
      this.getListByField()
    },
    // 查询省市接口
    getAreaList () {
      queryAreaList().then((res) => {
        if (res.code === 1000) {
          this.cityList = res.data
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    /** 搜索站点 */
    filterStation (query) {
      if (this.city.length == 0) {
        this.$message.warning("请先选择所在城市")
        this.stationList = []
        return
      }
      if (query !== '') {
        this.loading = true
        setTimeout(() => {
          this.loading = false
          let data = { stationName: query, city: this.city[1] }
          querySiteFuzzy(data).then((res) => {
            if (res.code === 1000) {
              this.stationList = res.data
            }
          })
        }, 200)
      } else {
        this.stationList = []
      }
    },
    // 模糊搜索线路
    remoteMethod (query) {
      if (query !== '') {
        this.loading = true
        setTimeout(() => {
          this.loading = false
          let data = { lineName: query }
          queryLineDetailFuzzy(data).then((res) => {
            if (res.code === 1000) {
              this.options = res.data
            }
          })
        }, 200)
      } else {
        this.options = []
      }
    },
  }
}
</script>
<style lang="scss" scoped>
.check-car {
  height: 100%;
  width: 100%;
  border-radius: 4px;
  padding: 16px 20px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  /deep/.el-date-editor .el-range-separator {
    line-height: 24px;
  }
  .main {
    display: flex;
    .main-table {
      margin-right: 20px;
      width: 40vw;
    }
    .main-map {
      margin-top: 10px;
      width: 100%;
      height: calc(80vh - 110px);
    }
  }
}
.theme-project-bus {
  .check-car {
    background: #ffffff;
    box-shadow: 0 0 4px 0 rgba($color: #343434, $alpha: 0.1);
  }
}
</style>